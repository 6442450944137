import React from 'react';
// import classes from "./Layout.module.css"
const MessageSenderNav = () => {

    return (
        <ul>
            <li>
                <a href="/sms/campaigns">Campaigns</a>
            </li>
            <li>
                <a href="/formats">Message Formatter</a>

            </li>
            <li>

                <a onClick={() => localStorage.removeItem("token")} href="/auth">
                    Logout
            </a>
            </li>
        </ul>
    )
}


export default MessageSenderNav