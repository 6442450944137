//@ts-nocheck
import React, { useState, useEffect, useContext, } from "react";
import { AuthUserContext } from "../../context/auth"
import { Offline, Online } from "react-detect-offline";
import SocketIOClient from 'socket.io-client';

import classes from "./Layout.module.css";
import { NumberChangeContext } from "../../context/numberchangers";
import MessageSenderNav from "./MessageSenderNav"
import LegalNav from "./LegalNav"
import Logo from "./logo.png";
import tk from "../../images/tk.gif";
import { formatter } from "../../helperFunctions/currencystring";

import { withRouter } from "react-router-dom";
// import LoadScreen from "../../containers/LoadScreen/LoadScreen";
// import MoneySound from "../../assets/MONEYWIN.wav"
import socketIOClient from "socket.io-client";

import REQ from "../../helperFunctions/REQ";
import GLOBAL from "../../containers/GLOBAL/GLOBAL";
import Sidebar from "./Sidebar";
import Logosvg from "../../images/advancerlogo.png"
// import { canvasStyles } from '../../constants/styles';
// import ReactCanvasConfetti from 'react-canvas-confetti';
import { faArrowCircleRight, faHourglass, faMoneyBill, faMoneyBillAlt, faMoneyBillWaveAlt, faNewspaper, faRecycle, faRedo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAlert } from "react-alert";


function Layout({ children, history }) {
  let [showingMessageDropdown, setShow] = useState(false);
  // let [loadingScreen, setShowLoadingScreen] = useState(true);
  let [status, setStatus] = useState(true);
  // let [audio] = useState(new Audio(MoneySound));
  // let [playing, setPlaying] = useState(false);

  // let [socket] = useState(SocketIOClient(GLOBAL.domainClickSocket));
  const [socket, setSocket] = useState(null);

  let [fullName, setFullName] = useState("");
  let [userid, setuserid] = useState("");
  let [balance, setbalance] = useState("");
  // let [balancechart, setbalancechart] = useState(0);
  let [viewerid, setviewerid] = useState("");
  let [allowBLAAcess, setallowBLAAcess] = useState(false);
  let [revtoday, setrevtoday] = useState(0);

  let [noconvstats, setnoconvstats] = useState(false)
  let [sidebar, showSideBar] = useState(false);
  let [advertiserId, setAdvertiserId] = useState(null)
  let [showingraw, setshowrawsub] = useState(false);
  let [revloading, setrevloading] = useState(false);

  const myAlert = useAlert();


  const {
    balancechart,
    setbalancechart,
    // getInstance,
  } = useContext(NumberChangeContext);


  const {
    user,
    profitsharer
  } = useContext(AuthUserContext)
  useEffect(() => {
    const {
      _id,
      fullName,
      status,
      balance,
      allowBLAAcess,
      no_conversion_stats,
      showrawsub,
      advertiser_id,
    } = user;
    setSocket(socketIOClient(GLOBAL.domainClickSocket));
    setshowrawsub(showrawsub)
    setuserid(_id);
    setnoconvstats(no_conversion_stats || false)
    if (profitsharer?._id) {
      console.log("fetching balancechart");
      setviewerid(profitsharer._id);
      fetchpaydata(profitsharer?._id);
    }

    if (advertiser_id) {
      setAdvertiserId(advertiser_id)
    }
    if (status === "number-changer") {
      fetchNumberChangerBalance()
    }


  

    setFullName(fullName);
    setallowBLAAcess(allowBLAAcess);
    if (status !== "superadmin" && !allowBLAAcess) {
      const pathnames = [
        "/twilio",
        "/gorilla",
        "/twilio-account-2",
        "/twilio-account-2-beta",
        "/namecheap",
        "/blacklist",
        "/formatter",
        "/myscrubs",
      ];

      if (pathnames.indexOf(history.location.pathname) !== -1) {
        return (document.location.href = "/404");
      }
    } else if (status === "superadmin") {
      const pathnames = [
        "/statistics",
      ];

      if (pathnames.indexOf(history.location.pathname) !== -1) {
        return (document.location.href = "/404");
      }
    }
    if (status === "viewer") {
      const pathnames = [
        "/conversion-tracker",
        "/landers",
        "/sms",
        "/sending-numbers",
        "/gorilla",
        "/twilio-account-2",
        "/twilio-account-2-beta",
        "/twilio",
        "/blacklist",
      ];
      if (pathnames.indexOf(history.location.pathname) !== -1) {
        return (document.location.href = "/404");
      }
    }
    setStatus(status);
    fetchConversions(status)

    setbalance(balance);
    // setShowLoadingScreen(false);

  }, []);


  useEffect(()=>{
    if(socket && user.status === "superadmin"){

      socket.on("conversion_update", (update)=>{
  
        console.log({
          update,
          conversion_update: true,
        })

        myAlert.show(`A conversion just came from ${update.traffic} on ${update.source}'s data => $${update.payout}`, {type:"success"})

        fetchConversions()
        
      })

      

    }
  },[socket])

  const fetchpaydata = async (id) => {
    try {
      let data = await REQ(
        "GET",
        `${GLOBAL.domainDataowner}/api/payment-summary-affilliate/${id}`,
        null,
        true
      );

      data = data.data;

      console.log(data);
      setbalancechart(data?.totalowed);
    } catch (error) {
      // alert(error?.response?.data?.message || "an error occured");
      console.log(error)
    }
    // setloading(false);
  };

  const fetchNumberChangerBalance = async () => {
    try {
      let data = await REQ(
        "GET",
        `${GLOBAL.domain}/api/numberchangerpayments/balance`,
        null,
        true
      );

      data = data.data;

      console.log(data);
      setbalancechart(data?.balance);
    } catch (error) {
      console.log(error)
      // alert(error?.response?.data?.message || "an error occured");
    }
    // setloading(false);
  };

  const fetchConversions = async(status) =>{
    // fetchConversions
console.log("awesome!!")
  try {
    setrevloading(true)

    let url = `${GLOBAL.domainClickGet}/api/conversions-all?limit=8&isdetailed=no&fixedRangeValue=0`
    let {data} = await REQ(
      "GET",
      url,
      null,
      true
    );
    // const {data} = await Axios.get( , {

    // })

    setrevtoday(data.totalPayout)


    console.log({dailyConversion: data})

  } catch (error) {
    console.log(error);
    
  } finally{
    setrevloading(false)
  }
  //
  }

  return (
    <>

      <Sidebar
        status={status}
        showingsidebar={sidebar}
        setSideBar={showSideBar}
      />
      <div className={classes.Layout}>
      {status == "superadmin" && <div className={classes.RevDiv}>
        <p className={classes.RevToday} style={{marginRight: 10}}>Today: {revloading? <img src={tk} height={15} style={{marginRight: 10}}/> :<span>{  formatter.format(`${revtoday}`)} </span> }<FontAwesomeIcon style={{marginRight: 10}} icon={faRedo} onClick={()=>{
          fetchConversions()
        }}/>   
        
        <FontAwesomeIcon style={{
          marginRight: 10
        }} icon={faHourglass} onClick={()=>{
          window.location.href = "/conversion-per-hour"
        }} />
        
        <FontAwesomeIcon icon={faArrowCircleRight} onClick={()=>{
          window.location.href = "/super/allrev"
        }} />
        
        
        </p>
     
        </div>}
        {/* <LinearProgress value={100} variant="determinate" /> */}
        <div className={classes.Header}>
          <p className={classes.logo}>
            <a href="/">
              <img width="100px" src={Logosvg} />
            </a>
          </p>
          <div
            className={classes.SnackBar}
            onClick={() => showSideBar(!sidebar)}
          >
            <div></div>
            <div></div>
            <div></div>
          </div>
          {status == "superadmin" ? (
            <ul className={classes.DesktopOnly}>
              <div className={classes.MessagingAppsCont}>
                <li onClick={() => setShow(!showingMessageDropdown)}>
                  Messaging Apps
                </li>
                <div className={classes.MessagingApps}>
           
                  {/* <div>
                    <a href="/sms/edge">
                      <div className={classes.fullWidth}>
                       Send with SMS Edge
                      </div>
                    </a>
                  </div> */}
                  <div>
                    <a href="/sms/homeport-minis">
                      <div className={classes.fullWidth}>
                        In-House SIM MACHINE
                      </div>
                    </a>
                  </div>
                  {/* <div>
                    <a href="/sms/homeport-minis">
                      <div className={classes.fullWidth}>
                        In-House SMS Platform
                      </div>
                    </a>
                  </div> */}
                </div>
              </div>

              <li>
                <a href="/namecheap">Bulk Forward</a>
              </li>

              <li>
                <a href="/splitter">File Splitter</a>
              </li>
              <li>
                <a href="/my-number-changers">Number Changers</a>
              </li>
              <li>
                <a href="/blacklist">Blacklist</a>
              </li>
              <li>
                <a href="/click-tracker">Clicks</a>
              </li>
              <li>
                <a href="/conversion-tracker">Conversions</a>
              </li>
              <li>
                <a href="/legal/leads">Optin Info</a>
              </li>
              <li>
                <a href="/advertisers">Advertisers</a>
              </li>
              <li>
                <a href="/message-formatter">Formatter</a>
              </li>
              <li>
                <a href="/cidattach/files">CID Attacher</a>
              </li>
              <li>
                <a href="/subid-create">Create Sub</a>
              </li>
              <li>
                <a href="/profit-sharers">Profit Sharers</a>
              </li>
              <li>
                <a
                  onClick={() => localStorage.removeItem("token")}
                  href="/auth"
                >
                  Logout
                </a>
              </li>
            </ul>
          ) : status === "visitor" ? (
            <ul>
              <li>
                <a href="/conversion-tracker">Conversion Tracker</a>
              </li>
              <li>
                <a
                  onClick={() => localStorage.removeItem("token")}
                  href="/auth"
                >
                  Logout
                </a>
              </li>
              {/* 
              <li className={classes.BalanceButton}>
                <a href="#">Balance: $ {balancechart}</a>
              </li> */}
            </ul>
          ) : status === "viewer" ? (
            <ul className={classes.navs}>

              {!noconvstats ? <li>
                <a href={showingraw ? "/my-statistics" : "/statistics"}>Conversion Stats</a>
              </li> : null}
              {allowBLAAcess ? (
                <li>
                  <a href="/myscrubs">My Data</a>
                </li>
              ) : null}
              <li>
                <a
                  onClick={() => localStorage.removeItem("token")}
                  href="/auth"
                >
                  Logout
                </a>
              </li>
              <div className={classes.UserName}>{fullName.substring(0, 1)}</div>

              {viewerid ? (
                <li className={classes.BalanceButton}>
                  <a href={`/balance/` + viewerid}>
                    Balance: $ {balancechart.toFixed(2)}
                  </a>
                </li>
              ) : (
                  null
                )}
            </ul>
          ) : status === "viewer-sub" ? (
            <ul className={classes.navs}>
              <li>
                <a href="/earnings">My Earnings</a>
              </li>
              {allowBLAAcess ? (
                <li>
                  <a href="/myscrubs">My Data</a>
                </li>
              ) : null}
              <li>
                <a
                  onClick={() => localStorage.removeItem("token")}
                  href="/auth"
                >
                  Logout
                </a>
              </li>
              <div className={classes.UserName}>{fullName.substring(0, 1)}</div>
            </ul>
          ) :


                  status === "number-changer" ? (
                    <ul>

                      <li>
                        <a

                          href="/sms/codes"
                        >
                          SMS  Verification Codes
                        </a>
                      </li>

                      <li>
                        <a
                          onClick={() => localStorage.removeItem("token")}
                          href="/auth"
                        >
                          Logout
                        </a>
                      </li>
                      <li className={classes.BalanceButton}>
                        <a href={`/numberchanger/balance/${userid}`}>Balance: $ {balancechart.toFixed(2)}</a>
                      </li>


                    </ul>
                  )

                    :
                    status === "message-sender" ?
                      <MessageSenderNav />
                      :
                      status === "legal" ?
                        <LegalNav advertiserId={advertiserId} />
                        :
                        (
                          <ul>

                            <li>
                              <a
                                onClick={() => localStorage.removeItem("token")}
                                href="/auth"
                              >
                                Logout
                           </a>
                            </li>


                          </ul>
                        )}
        </div>

        <div className={classes.ChildrenPanel}>
         <Offline> <p className={classes.OfflineStyle}>  You are not currently connected to the Internet. Some services may not function properly</p></Offline>
          {children}</div>
      </div>
    </>
  );
}

export default withRouter(Layout);
