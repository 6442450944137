import React from 'react';
// import classes from "./Layout.module.css"
const LegalNav = ({ advertiserId }) => {

    return (
        <ul>
            <li>
                <a href="/legal/leads">Optin Info</a>
            </li>
            {
                advertiserId && <li>
                    <a href={`/advertisers/${advertiserId}`}>Network Payment</a>
                </li>
            }

            <li>

                <a onClick={() => localStorage.removeItem("token")} href="/auth">
                    Logout
            </a>
            </li>
        </ul>
    )
}


export default LegalNav